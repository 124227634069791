<template>
  <div class="main">
    <div class="banner"></div>
    <div class="cha">
      <div class="content">
        <div class="c_f">
          <div class="t_img">
            <div class="title">
              <b>产品特性</b>
              <img
                src="../../assets/img/productList/wx/wechat_title.png"
                alt=""
              />
            </div>
            <div>
              微信公众号管理平台是一个微信SaaS工具产品，<br />
              具备各类微信公众号管理的常用功能。
            </div>
            <img
              style="margin-top: 87px"
              src="../../assets/img/productList/wx/wx_c_3.png"
              alt=""
            />
          </div>
          <div class="list">
            <div class="t">
              <img
                src="../../assets/img/productList/wx/wechat_c_title.png"
                alt=""
              />
            </div>
            <div class="item" v-for="(i, index) in cl" :key="index">
              <div class="icon">
                <img :src="i.icon" alt="" />
              </div>
              <div>
                <div class="title">{{ i.title }}</div>
                <div>{{ i.text }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content value">
      <div class="title_b">
        <b>平台价值</b>
        <div class="s">
          通过这一平台，可以实现公众平台粉丝及用户的标签化运营及数据化运营<br />
          能极大提升运营效率，带来业务及营销价值。
        </div>
        <div class="icon"></div>
      </div>
      <img src="../../assets/img/productList/wx/wechat_value_img.jpg" alt="" />
    </div>
    <div class="pf">
      <div class="content">
        <div class="list">
          <div class="title">
            <div>
              产品功能
              <img
                src="../../assets/img/productList/wx/wechat_title.png"
                alt=""
              />
            </div>
            <div class="s">
              面向市场，满足用户需求的的saas综合运营服务平台。
            </div>
          </div>

          <div
            class="item"
            v-for="(i, index) in pfList"
            :key="index"
            :style="{ backgroundImage: 'url(' + i.img + ')' }"
          >
            <div class="num">{{ index + 1 }}</div>
            <div class="text">{{ i.text }}</div>
            <div class="s">\ {{ i.s }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="title_b">
        <b>实现与获得</b>
        <div class="s">
          自主化的搭建工具，包括：活动灵活配置，公众号配置，模板消息配置；<br />
          灵活性的分析工具：数据统计分析，用户分组管理，具备数据统计功能的渠道二维码生成。
        </div>
        <div class="icon"></div>
      </div>
      <div class="card_4">
        <div class="card" v-for="(i, index) in ss" :key="index">
          <div class="img">
            <img :src="i.img" alt="" />
          </div>
          <div class="title">{{ i.title }}</div>
          <div v-html="i.text"></div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="title_b">
        <b>案例展示</b>
        <div class="s">通讯运营商微信公众号运营平台</div>
        <div class="icon"></div>
      </div>

      <div class="img_4">
        <img src="../../assets/img/productList/wx/wechat_case_1.jpg" alt="" />
        <img src="../../assets/img/productList/wx/wechat_case_2.jpg" alt="" />
        <img src="../../assets/img/productList/wx/wechat_case_3.jpg" alt="" />
        <img src="../../assets/img/productList/wx/wechat_case_4.jpg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cl: [
        {
          icon: require("../../assets/img/productList/wx/wechat_c_icon_1.png"),
          title: "菜单管理",
          text: "Menu management",
        },
        {
          icon: require("../../assets/img/productList/wx/wechat_c_icon_2.png"),
          title: "消息管理",
          text: "Message management",
        },
        {
          icon: require("../../assets/img/productList/wx/wechat_c_icon_3.png"),
          title: "用户标签管理",
          text: "User label management",
        },
        {
          icon: require("../../assets/img/productList/wx/wechat_c_icon_4.png"),
          title: "数据管理",
          text: "Data management",
        },
        {
          icon: require("../../assets/img/productList/wx/wechat_c_icon_5.png"),
          title: "图文分组精准推送",
          text: "Picture and text push",
        },
        {
          icon: require("../../assets/img/productList/wx/wechat_c_icon_6.png"),
          title: "客服消息主动送达",
          text: "Customer service active push",
        },
        {
          icon: require("../../assets/img/productList/wx/wechat_c_icon_7.png"),
          title: "PUSH消息精准推送",
          text: "Accurate message push",
        },
      ],

      pfList: [
        {
          img: require("../../assets/img/productList/wx/wechat_function_1.png"),
          text: "多账号对接公众号",
          s: "MULTI ACCOUNT DOCKING",
        },
        {
          img: require("../../assets/img/productList/wx/wechat_function_2.png"),
          text: "建立价值转换体系",
          s: "VALUE TRANSFORMATION SYSTEM",
        },
        {
          img: require("../../assets/img/productList/wx/wechat_function_3.png"),
          text: "搭建综合营销工具平台",
          s: "MARKETING TOOL PLATFORM",
        },
        {
          img: require("../../assets/img/productList/wx/wechat_function_4.png"),
          text: "对接微信公众号基础配置",
          s: "BASIC CONFIGURATION",
        },
        {
          img: require("../../assets/img/productList/wx/wechat_function_5.png"),
          text: "建立数据分析统计工具",
          s: "DATA ANALYSIS TOOLS",
        },
      ],
      ss: [
        {
          img: require("../../assets/img/productList/wx/wechat_realization_1.png"),
          title: "价值转换",
          text: "通过各类工具搭配使用实现用户、<br/>引流、变现等各种价值的转换",
        },
        {
          img: require("../../assets/img/productList/wx/wechat_realization_2.png"),
          title: "指标达成",
          text: "有效的助力微信公众号运营，更加<br/>高效的达成指标",
        },
        {
          img: require("../../assets/img/productList/wx/wechat_realization_3.png"),
          title: "便捷配置平台",
          text: "轻松管理微信公众号，方便快捷完<br/>成各类平台配置",
        },
        {
          img: require("../../assets/img/productList/wx/wechat_realization_4.png"),
          title: "数据统计分析",
          text: "采集平台各类数据进行统计，根据<br/>运营需求进行全面分析",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.banner {
  background-image: url("../../assets/img/productList/wx/banner.jpg");
}
.cha {
  background: url("../../assets/img/productList/wx/wechat_c_bg.jpg");
  background-size: 2560px 800px;
  background-repeat: repeat-y;
  background-position: center;
  height: 800px;
  padding: 50px 0;
  .c_f {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .t_img {
      font-size: 16px;
      color: #515151;
      .title {
        font-size: 40px;
        color: #000000;
        margin-bottom: 32px;
        img {
          vertical-align: -11px;
          margin-left: 24px;
        }
      }
    }
  }
  .list {
    width: 670px;
    background-color: #ffffff;
    box-shadow: -2px 4px 20px 0px rgba(84, 110, 133, 0.06);
    border-radius: 10px;
    padding: 42px 0 60px 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .t {
      width: 511px;
      margin-left: auto;
      margin-right: 26px;
      margin-bottom: 60px;
    }
    .item {
      width: 300px;
      display: flex;
      font-size: 14px;
      color: #818181;
      align-items: center;
      margin-bottom: 70px;
      &:last-child {
        margin-bottom: 0;
      }
      .icon {
        width: 70px;
        height: 70px;
        margin-right: 20px;
      }
      .title {
        font-size: 22px;
        color: #313131;
        font-weight: bold;
      }
    }
  }
}
.value {
  padding-bottom: 180px;
  .title_b {
    margin-top: 64px;
  }
  img {
    margin-top: 38px;
  }
}
.pf {
  background: url("../../assets/img/productList/wx/wechat_function_bg.jpg")
    no-repeat center / 2560px 760px #f6f6f5;
  height: 760px;
  .list {
    padding-top: 48px;
    .title {
      font-size: 40px;
      font-weight: bold;
      color: #2f75e7;
      img {
        width: 30px;
        height: 30px;
        vertical-align: -11px;
        margin-left: 24px;
      }
      .s {
        font-size: 16px;
        color: #515151;
        font-weight: normal;
        margin-top: 10px;
      }
    }
    .item {
      height: 71px;
      margin-top: 50px;
      background-position-x: 58px;
      background-size: auto 60px;
      background-repeat: no-repeat;
      display: flex;
      align-items: flex-end;
      .num {
        width: 44px;
        height: 44px;
        background-image: linear-gradient(50deg, #2196f3 0%, #1b65fb 100%);
        border-radius: 50%;
        color: #ffffff;
        font-size: 26px;
        text-align: center;
        line-height: 44px;
      }
      .text {
        font-size: 24px;
        line-height: 36px;
        color: #000000;
        font-weight: bold;
        margin-left: 32px;
        margin-right: 10px;
      }
      .s {
        font-size: 14px;
        line-height: 30px;
        color: #c2b274;
      }
    }
  }
}
.card_4 {
  display: flex;
  justify-content: space-between;
  margin-top: 66px;
  .card {
    width: 245px;
    height: 205px;
    padding: 40px 20px;
    background-color: #ffffff;
    box-shadow: 0px 3px 14px 0px rgba(5, 5, 5, 0.03);
    border-radius: 6px;
    border: solid 1px #eae5d3;
    font-size: 16px;
    color: #818181;
    position: relative;
    top: 0;
    transition: 0.13s;
    .title {
      font-size: 22px;
      font-weight: bold;
      line-height: 60px;
      color: #313131;
    }
    .img {
      width: 60px;
      height: 60px;
    }
    &:hover {
      box-shadow: 0px 3px 14px 0px rgba(33, 150, 243, 0.17);
      border: solid 1px #2196f3;
      top: -10px;
    }
  }
}
.img_4{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 30px;
  margin-bottom: 135px;
  img{
    margin-top: 35px;
  }
}
</style>